import { environment } from '../../../../../environments/environment';
import { LogoUpdateOption } from '../../../../models/user';

const BASE_URL = environment.api;

export const PROJECT_BASE_URL = BASE_URL + '/auth/project';

export const CREATE_PROJECT_POST_URL = PROJECT_BASE_URL + '/create';

export const VALIDATE_PROMPT = CREATE_PROJECT_POST_URL + '/prompt/validate';

export const GET_ALL_PROJECTS = PROJECT_BASE_URL + '/list';

export const STUDIO_ENTERED_URL = PROJECT_BASE_URL + '/studio_entered';

export const GET_PROJECT_CODE = PROJECT_BASE_URL + '/code';

export const UPDATE_PROJECT_PROPERTY = PROJECT_BASE_URL;

export const CREATIVE_STATUS = '/creative-status';

export const ADD = '/add';

export function getProjectGeneralUrl(projectId: string): string {
    if (!projectId) {
        throw new Error(`Could not get recording scene url because recording id is null`);
    }
    return `${PROJECT_BASE_URL}/${projectId}`;
}

export function getProjectDELETEUrl(projectId) {
    return getProjectGeneralUrl(projectId);
}
export function getDuplicateProjectPOSTUrl(projectId) {
    return `${getProjectGeneralUrl(projectId)}/duplicate`;
}
const projectStyleRouteName = '/style';

function getProjectStyleUrl(projectId: string): string {
    return `${PROJECT_BASE_URL}/${projectId}/style`;
}

export function getProjectUpdateLogoPOSTUrl(projectId: string, whichLogo: LogoUpdateOption): string {
    if (!projectId) {
        throw new Error(`Could not get update logo URL because project ID is null`);
    }

    const styleUrl = getProjectStyleUrl(projectId); // Assumes this function generates the base style URL
    return `${styleUrl}/logo?whichLogo=${whichLogo}`; // Appends the `whichLogo` query parameter
}
export function getProjectGoBackToDefaultBrandingPUTUrl(projectId: string) {
    if (!projectId) {
        throw new Error(`Could not get  because project id is null`);
    }
    const styleUrl = getProjectStyleUrl(projectId);

    return `${styleUrl}/default-brand`;
}

export function getProjectUpdateColorsPUTUrl(projectId: string) {
    if (!projectId) {
        throw new Error(`Could not get  because project id is null`);
    }
    const styleUrl = getProjectStyleUrl(projectId);

    return `${styleUrl}/colors`;
}

export function getProjectUpdateDesignPUTUrl(projectId: string) {
    if (!projectId) {
        throw new Error(`Could not get update design url because project id is null`);
    }
    const styleUrl = getProjectStyleUrl(projectId);
    return `${styleUrl}/design`;
}
export function getProjectUpdateAiVoiceChosenPUTUrl(projectId: string): string {
    if (!projectId) {
        throw new Error(`Could not get recording scene url because recording id is null`);
    }
    return `${PROJECT_BASE_URL}/${projectId}/ai-voice`;
}
