import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { ArchiveFilterPipe } from 'src/app/pipes/archiveFilter.pipe';
import { ThumbnailPipe } from 'src/app/pipes/post-production-pipes';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullScreenLoadingComponent } from '../../components/minor/full-screen-loading/full-screen-loading.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FileDropComponent } from '../../components/file-drop/file-drop.component';
import { ColorPickerRGBADirective } from '../../directives/color-picker-rgba.directive';
import { ColorPickerModule } from 'ngx-color-picker';
import { RenderedDirective } from 'src/app/directives/rendered.directive';
import { FeatureFlagDirective } from '../../directives/feature-flag.directive';
import { LoadingIndicatorComponent } from '../../components/minor/loading-indicator/loading-indicator.component';
import { TimeFormatPipe } from '../../pipes/time-format.pipe';
import { LottieVideoComposedComponent } from '../../components/lottie-video-composed/lottie-video-composed.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DynamicLottieComponent } from '../../components/dynamic-lottie/dynamic-lottie.component';
import { ScrubBarComponent } from '../../components/minor/scrub-bar/scrub-bar.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { SilhouetteComponent } from 'src/app/components/silhouette/silhouette.component';
import { VideoSourceDirective } from 'src/app/directives/video-source.directive';
import { SidenavToggleComponent } from 'src/app/components/minor/sidenav-toggle/sidenav-toggle.component';
import { EditableModule } from '@ngneat/edit-in-place';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ResizableModule } from 'angular-resizable-element';
import { GradientPickerComponent } from 'src/app/components/minor/gradient-picker/gradient-picker.component';
import { ButtonToDisplayLinksComponent } from '../../components/minor/button-to-display-links/button-to-display-links.component';
import { CurrentUserImgComponent } from '../../components/minor/current-user-img/current-user-img.component';
import { FeedbackPopupButtonDirective } from '../../directives/feedback-popup-button.directive';
import { AudioListComponent } from '../../components/shared/major/list/audio-list/audio-list.component';
import { AudioListManagerService } from '../../components/shared/major/list/audio-list/audio-list-manager.service';
import { WaveformComponent } from '../../components/minor/waveform/waveform.component';
import { NgOptimizedImage } from '@angular/common';
import { AudioListItemComponent } from '../../components/shared/major/list/audio-list/audio-list-item/audio-list-item.component';
import { AudioSortPipe } from 'src/app/components/shared/major/list/audio-list/audio-list-sort.pipe';

@NgModule({
    declarations: [
        ArchiveFilterPipe,
        ThumbnailPipe,
        FullScreenLoadingComponent,
        LoadingIndicatorComponent,
        FileDropComponent,
        ColorPickerRGBADirective,
        RenderedDirective,
        FeatureFlagDirective,
        TimeFormatPipe,
        LottieVideoComposedComponent,
        ButtonToDisplayLinksComponent,
        DynamicLottieComponent,
        ScrubBarComponent,
        SilhouetteComponent,
        VideoSourceDirective,
        SidenavToggleComponent,
        GradientPickerComponent,
        WaveformComponent,
        FeedbackPopupButtonDirective,
        CurrentUserImgComponent,
        AudioListComponent,
        AudioListItemComponent,
        AudioSortPipe,
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        LottieComponent,
        ColorPickerModule,
        SlickCarouselModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        NgOptimizedImage,
        VgBufferingModule,
        VgStreamingModule,
        EditableModule,
        DragDropModule,
        ResizableModule,
    ],
    exports: [
        ScrubBarComponent,
        DynamicLottieComponent,
        AudioListComponent,
        AudioListItemComponent,
        SilhouetteComponent,
        SlickCarouselModule,
        LottieVideoComposedComponent,
        TimeFormatPipe,
        LottieComponent,
        WaveformComponent,

        CommonModule,
        MaterialModule,
        FormsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FullScreenLoadingComponent,
        LoadingIndicatorComponent,
        EditableModule,
        ResizableModule,
        FileDropComponent,
        ColorPickerRGBADirective,
        RenderedDirective,
        ColorPickerModule,
        FeatureFlagDirective,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        VgStreamingModule,
        VideoSourceDirective,
        SidenavToggleComponent,
        DragDropModule,
        GradientPickerComponent,
        ButtonToDisplayLinksComponent,
        CurrentUserImgComponent,
        FeedbackPopupButtonDirective,
    ],
    providers: [
        provideLottieOptions({
            player: () => import('lottie-web'),
        }),
        AudioListManagerService,
    ],
})
export class SharedModule {}
