import { IVideoTag } from './defines';
import { IDesignGroup } from './design.model';
import { PaymentFeatureFlagEvents } from '../services/feature-service.service';

export type LogoUpdateOption = 'logo' | 'logow' | 'both';
export type SingleLogoOption = Exclude<LogoUpdateOption, 'both'>;

export class User {
    public created_at;
    public email: string;
    public name;
    public profileImgUrl;
    public _id: any;
    public token: string;
    public active: boolean;
    public socketId: string;
    public username: string;
    public branding?: IBranding;
    public credits: number;
    public onboarding: onboardingObj[];
    public configs: IUserConfigs;
    public plan: PlanEnum;
    public featureGroup: FeatureGroupEnum;
    public isShuffllUser: boolean = false;
    hasConsumedFreeOffer: boolean;
    constructor(
        created_at = null,
        email = null,
        name,
        profileImgUrl = null,
        id = null,
        token: string,
        username = null,
        onboarding = null,
        configs = null
    ) {
        this.email = email;
        if (email) {
            this.isShuffllUser = email.toLowerCase().includes('shuffll');
        }
        this.name = name;
        this.profileImgUrl = profileImgUrl;
        this._id = id;
        this.token = token;
        this.username = username;
        this.onboarding = onboarding;
        this.plan = PlanEnum.BASIC;
        this.featureGroup = FeatureGroupEnum.NO_GROUP;
    }
}

export interface GetUsersResponse {
    users: User[];
    count: number;
}

export interface onboardingObj {
    key: string;
    value: string;
}

export interface IWalkthroughTasks {
    id: string;
    isCompleted: boolean;
}

export interface IUserConfigs {
    walkthroughTasks: IWalkthroughTasks[];
}

export interface IColorMap {
    colorPrimary?: string;
    colorSecondary?: string;
    colorExtra?: string;
}

export enum UserVideoIdeaStatusEnum {
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    NOT_STARTED = 'NOT_STARTED',
    FAILED = 'FAILED',
}

export interface IUserVideoIdea {
    shortTitle: string;
    prompt: string;
    designGroup: IDesignGroup;
}

export interface IBranding {
    enabled?: boolean;
    design?: string;
    prompts: [{ tag: IVideoTag; suggestions: string[] }];
    company?: {
        id?: string;
        name?: string;
        domain?: string;
    };
    logo?: string;
    logoW?: string;
    colors?: IColorMap;
    logoColors?: {
        colorPrimary?: string;
        colorSecondary?: string;
        colorExtra?: string;
    };
    about?: string;
    isDefault?: boolean;
    videoIdeas: IUserVideoIdea[];
    videoIdeaStatus: UserVideoIdeaStatusEnum;
}

export enum PlanEnum {
    FREE = 'free',
    BASIC = 'basic',
    PRO = 'Pro',
    PREMIUM = 'premium',
}

export enum FeatureGroupEnum {
    MUST_PAY = 'must_pay',
    FREE = 'free',
    NO_GROUP = 'no_group',
}
