<div class="audio-item">
    <div class="audio-image-and-play-wrapper" [class.has-image]="audioConfig.image">
        <div
            *ngIf="audioConfig.image"
            class="image"
            [style.background-image]="'url(' + basePath + audioConfig.image + ')'"></div>
        <button
            mat-mini-fab
            class="cool-button gray play-pause small-icon-button"
            color="secondary"
            matTooltip="Voice Preview"
            (click)="onTogglePlay()">
            <mat-icon>{{ currentPlayingId === audioConfig.id ? 'pause' : 'play_arrow' }}</mat-icon>
        </button>
    </div>

    <div class="main-section">
        <div class="top-row row row-spacebetween">
            <div class="voice-details">
                <span class="voice-title">{{ audioConfig.name }}</span>
            </div>
            <div class="voice-details">
                <span class="voice-subtitle">{{ audioConfig.title }}</span>
            </div>

            <!--            Selected label   -->
            <ng-container *ngIf="selectMode; else changeMode">
                <div class="selected primary row-centered gapped" *ngIf="audioConfig.isSelected">
                    <mat-icon class="smaller-icon">check</mat-icon>
                    Selected
                </div>

                <button
                    *ngIf="!audioConfig.isSelected"
                    [disabled]="disabled"
                    mat-raised-button
                    class="wider-choose-button"
                    [color]="audioConfig.isSelected ? 'primary' : 'secondary'"
                    [ngClass]="audioConfig.isSelected ? 'no-click' : ''"
                    (click)="onChooseAudio()"
                    style="font-size: 9px">
                    <ng-container *ngIf="!audioConfig.isLoading; else loader">
                        {{ audioConfig.isSelected ? 'Selected' : 'Select' }}
                    </ng-container>
                    <ng-template #loader>
                        <mat-progress-spinner
                            strokeWidth="4"
                            class="loader-icon white-spinner"
                            color="white"
                            mode="indeterminate"
                            diameter="25">
                        </mat-progress-spinner>
                    </ng-template>
                </button>
            </ng-container>
            <ng-template #changeMode>
                <button
                    *ngIf="audioConfig.isSelected"
                    [disabled]="disabled"
                    mat-raised-button
                    color="primary"
                    (click)="onAskToChange()"
                    class="wider-choose-button">
                    Change
                </button>
            </ng-template>
        </div>
        <div class="waveform-wrap">
            <app-waveform [uniqueId]="audioConfig.id" [audioUrl]="basePath + audioConfig.audioPath" [height]="40">
            </app-waveform>
        </div>
    </div>

    <audio #audio [src]="basePath + audioConfig.audioPath"></audio>
</div>
