import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'audioSort',
})
export class AudioSortPipe implements PipeTransform {
    transform(audioConfigs: any[], showAll: boolean, selectedId?: string): any[] {
        if (!audioConfigs) {
            return [];
        }

        // Create a copy to avoid modifying the original array
        const sortedConfigs = [...audioConfigs];

        if (selectedId) {
            // Find the selected item
            const selectedIndex = sortedConfigs.findIndex((config) => config.id === selectedId);

            if (selectedIndex !== -1) {
                // Remove the selected item and place it at the beginning
                const selectedItem = sortedConfigs.splice(selectedIndex, 1)[0];
                sortedConfigs.unshift(selectedItem);
            }
        }

        // If showAll is false, only return the selected item
        if (!showAll && selectedId) {
            return sortedConfigs.filter((config) => config.id === selectedId);
        }

        return sortedConfigs;
    }
}
