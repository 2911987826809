<div
    class="card"
    [class.exporting]="project?.statuses?.general === ProjectStatusEnum.EXPORTING"
    [class.minimal]="minimal">
    <div
        class="card__exporting-overlay"
        (mouseenter)="$event.stopPropagation()"
        *ngIf="project.statuses?.general === ProjectStatusEnum.EXPORTING">
        EXPORTING...
    </div>
    <div
        class="card__image"
        (mouseenter)="playAnimation(this.project.iconLayout._id)"
        (mouseleave)="pauseAnimation(this.project.iconLayout._id)">
        <div
            class="free-project"
            *ngIf="project.isPlayground && (this.paymentStateManager.paymentStatus$ | async)?.plan === 'immediate'">
            🎁 Trial Project
        </div>
        <dynamic-lottie
            [config]="{
                layout: this.project.iconLayout,
                dynamicLottieChanges: this.personalizedDynamics,
                basePath: '',
            }"
            [lottieOptions]="{ autoplay: false }"
            (animationCreated)="animationCreated($event, this.project.iconLayout._id)">
        </dynamic-lottie>
    </div>
    <!-- <div
[style.background-image]="'url(' + config.baseCdnUrl + project.icon + ')'"
class="card__image"
alt=""></div> -->
    <div class="card__overlay">
        <div class="card__header">
            <!--            <svg class="card__arc" xmlns="http://www.w3.org/2000/svg">-->
            <!--                <path />-->
            <!--            </svg>-->
            <!--      <img class="card__thumb" src="https://i.imgur.com/sjLMNDM.png" alt=""/>-->
            <div class="card__header-text">
                <h3 class="card__title">{{ project.name }}</h3>
                <span class="card__statusarea row-spacebetween" *ngIf="!minimal">
                    {{ project.isProjectOwner ? 'Created by me' : 'Shared with me' }} ●
                    {{ helperFunctions.timeAgo(project.createdAt) }}
                    <div
                        *ngIf="project?.statuses?.general !== ProjectStatusEnum.DONE"
                        class="card__statusarea__status"
                        [class.created]="true"
                        [ngClass]="project?.statuses?.general ?? ''">
                        {{ project?.statuses?.general ?? '' }}
                    </div>
                    <button
                        *ngIf="project.statuses?.general === ProjectStatusEnum.DONE"
                        mat-raised-button
                        class="card__statusarea__export-button"
                        [matBadge]="project.edits?.length - 1 ?? 0">
                        Exports
                    </button>
                </span>
            </div>
        </div>
        <p class="card__description">Prompt: {{ project.creative?.prompt }}</p>
    </div>
</div>
