import { Injectable } from '@angular/core';
import { FeatureService, PaymentFeatureFlagEvents, PaymentVariantNames } from '../feature-service.service';
import { PlanEnum, User } from '../../models/user';
import { InAppNotificationService } from '../../pages/private/host/services/in-app-notification.service';
import mixpanel from 'mixpanel-browser';
export interface IUserPaymentStatus {
    needToPay: boolean;
    needToPayNow: boolean;
    plan: PaymentVariantNames | null;
    abilityToClosePaywall: boolean;
    paywallTimeout: number;
}
@Injectable({
    providedIn: 'root',
})
export class PaymentUtilsService {
    constructor(
        private featureService: FeatureService,
        private inAppNotificationService: InAppNotificationService
    ) {
        this.inAppNotificationService.mixPanelIntialized$.subscribe(async (isInit) => {
            if (!isInit) return;
            const data = await this.getUserChargeBehaviorAsync();

            mixpanel.people.set({
                chargeBehavior: data,
            });
        });
    }

    async checkIfUserNeedsToPayAsync(user: User): Promise<IUserPaymentStatus> {
        const defaultValues: IUserPaymentStatus = {
            needToPayNow: false,
            needToPay: false,
            plan: null,
            abilityToClosePaywall: true,
            paywallTimeout: 4000,
        };
        if (user?.plan !== PlanEnum.FREE) {
            return defaultValues;
        }
        const plan = await this.getUserChargeBehaviorAsync();
        if (plan === 'free') {
            return defaultValues;
        }

        const closePaywallData = await this.getAbilityToClosePaywallAsync();
        const paywallTimeout = await this.getPaywallTimeoutAsync();
        switch (plan) {
            case 'trial':
                return {
                    needToPay: true,
                    needToPayNow: true,
                    plan: 'trial',
                    abilityToClosePaywall: closePaywallData,
                    paywallTimeout: paywallTimeout,
                };
            case 'immediate':
                return {
                    needToPay: true,
                    needToPayNow: user.hasConsumedFreeOffer,
                    plan: 'immediate',
                    abilityToClosePaywall: closePaywallData,
                    paywallTimeout: paywallTimeout,
                };
            default:
                return defaultValues;
        }
    }

    async getAbilityToClosePaywallAsync(): Promise<boolean> {
        return this.featureService.getFeatureAccessValue('ability_to_close_paywall');
    }

    async getPaywallTimeoutAsync(): Promise<number> {
        return this.featureService.getPaywallTimeout();
    }
    async getUserChargeBehaviorAsync(): Promise<PaymentVariantNames | 'free'> {
        if (!(await this.checkIfUserShouldSeePaywallAsync())) {
            return 'free';
        }
        const { name: paymentVariableName } = this.featureService.getPaymentVariant();
        return paymentVariableName;
    }

    async checkIfUserShouldSeePaywallAsync() {
        return this.featureService.getFeatureAccessValue('should_see_paywall');
    }
}
