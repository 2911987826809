import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { IFont } from '../../../models/configs/fonts.model';
import { ConfigurationAuthApiService } from '../../api/auth/config-auth-api.service';
import { FunctionsHelperService } from '../../functions-helper.service';

@Injectable({
    providedIn: 'root',
})
export class FontsStoreService {
    private _fonts$ = new BehaviorSubject<IFont[]>(null);
    public fonts$ = this._fonts$.asObservable();
    public loadedFonts = new Set<string>(); // Track loaded fonts

    constructor(
        private configAuthService: ConfigurationAuthApiService,
        private functionsHelper: FunctionsHelperService
    ) {
        this.configAuthService.getFonts$().subscribe({
            next: (fontsConfigs) => {
                if (!fontsConfigs) {
                    this._fonts$.error(new Error('Fonts configuration not found'));
                    return;
                }

                const fonts = fontsConfigs.fonts;
                this._fonts$.next(fonts);
            },
            error: (error) => {
                this._fonts$.error(error); // Propagates the error to all subscribers
            },
        });
    }

    getFontsAsync() {
        return this.functionsHelper.getDataAsync(this._fonts$);
    }
}
