import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Pipe,
    PipeTransform,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AudioListManagerService } from './audio-list-manager.service';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSliderDragEvent } from '@angular/material/slider';
import { WavesurferManagerService } from '../../../../minor/waveform/wavesurfer-manager.service';

export interface IAudioListComponentSettings {
    onOrOff: {
        toDisplayToggle: boolean;
        state: boolean;
    };
    volume: {
        doDisplay: boolean;
        min: number;
        max: number;
        step: number;
        currentValue: number;
    };
}

export interface IAudioConfig {
    id: string;
    name: string;
    title: string;
    audioPath: string;
    isLoading: boolean;
    isSelected: boolean;
    image: string | null;
}

@Component({
    selector: 'app-audio-list',
    templateUrl: './audio-list.component.html',
    styleUrl: './audio-list.component.scss',
})
export class AudioListComponent implements OnInit, OnDestroy {
    @ViewChildren('audio') audioElements!: QueryList<ElementRef<HTMLAudioElement>>;
    @Input('padded') padded = true;

    componentSettings: IAudioListComponentSettings;
    toDisableAudio = false;
    audioConfigs: IAudioConfig[];
    selectedAudio: IAudioConfig;
    alwaysShowAll: boolean;
    isLoadingData = false;

    onDestroy$ = new Subject<boolean>();

    currentPlayingIndex: number | null = null;
    currentPlayingId: string;
    basePath: string;
    selectedVoiceIndex: number;
    showAll = false;

    constructor(
        private audioListManager: AudioListManagerService,
        private configService: ConfigurationService,
        private wavesurferManager: WavesurferManagerService
    ) {
        this.basePath = this.configService.baseCdnUrl;
    }

    ngOnInit(): void {
        this.showAll = this.alwaysShowAll;
        this.audioListManager.audioOptions$.pipe(takeUntil(this.onDestroy$)).subscribe((audioOptions) => {
            this.audioConfigs = audioOptions;
        });
        this.audioListManager.selectedAudio$.pipe(takeUntil(this.onDestroy$)).subscribe((selectedAudio) => {
            this.selectedAudio = selectedAudio;
        });
        this.audioListManager.isLoadingData$.pipe(takeUntil(this.onDestroy$)).subscribe((isLoadingData) => {
            this.isLoadingData = isLoadingData;
        });
        this.audioListManager.alwaysShowAll$.pipe(takeUntil(this.onDestroy$)).subscribe((alwaysShowAll) => {
            this.alwaysShowAll = alwaysShowAll;
            this.showAll = this.alwaysShowAll;
        });

        this.audioListManager.audioListComponentSettings$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((componentSettings) => {
                this.componentSettings = componentSettings;
                if (componentSettings?.onOrOff?.toDisplayToggle) {
                    this.toDisableAudio = !componentSettings.onOrOff.state;
                }
            });

        this.audioListManager.stopPlayingSample$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.togglePlay(this.currentPlayingId, true);
        });

        this.wavesurferManager.triggerPlayFromWaves$.pipe(takeUntil(this.onDestroy$)).subscribe((uniqueId) => {
            this.wavesurferManager.triggerPauseFromOutside(this.currentPlayingId);
            this.playAudioFromWavesurf(uniqueId);
        });
    }

    togglePlay(id: string, onlyPause: boolean = false): void {
        /// Stopping current audio
        this.wavesurferManager.triggerPauseFromOutside(this.currentPlayingId);
        /// Playing new audio
        if (this.currentPlayingId === id) {
            this.currentPlayingId = null;
            return;
        }
        if (onlyPause) {
            return;
        }
        this.playAudioFromWavesurf(id);
        // const audioElements = this.audioElements.toArray();

        // // Determine which audio element to control
        // const selectedAudio =
        //     typeof index === 'number'
        //         ? audioElements[index]?.nativeElement // For list voices
        //         : this.audioElements.first?.nativeElement; // For selected voice
        //
        // if (!selectedAudio) {
        //     return;
        // }
        //
        // // Handle currently playing audio
        // if (this.currentPlayingIndex !== null && this.currentPlayingIndex !== index) {
        //     const currentlyPlayingAudio = audioElements[this.currentPlayingIndex]?.nativeElement;
        //     if (currentlyPlayingAudio) {
        //         currentlyPlayingAudio.pause();
        //         currentlyPlayingAudio.currentTime = 0;
        //     }
        // }

        // Toggle playback
        // if (
        //     this.currentPlayingIndex === index ||
        //     (typeof index !== 'number' && this.currentPlayingIndex === this.selectedVoiceIndex)
        // ) {
        //     selectedAudio.pause();
        //     this.currentPlayingIndex = null; // Reset when paused
        // } else {
        //     this.audioListManager.triggerStartPlayingSample();
        //     selectedAudio.play();
        //     this.currentPlayingIndex = typeof index === 'number' ? index : this.selectedVoiceIndex; // Update current index
        //     selectedAudio.onended = () => (this.currentPlayingIndex = null); // Reset when audio ends
        // }
    }

    private playAudioFromWavesurf(id: string) {
        this.currentPlayingId = id;
        this.audioListManager.triggerStartPlayingSample();
        this.wavesurferManager.triggerPlayFromOutside(id);
    }

    showAllVoices(): void {
        this.showAll = true;
    }

    chooseVoice(audio: IAudioConfig): void {
        if (!audio || audio.isSelected) {
            return;
        }
        if (!this.alwaysShowAll) {
            this.showAll = false;
        }
        this.audioListManager.triggerNewSelectForAudioId(audio.id);
    }

    onToggleChange(event: MatSlideToggleChange) {
        if (!event) return;
        this.audioListManager.triggerOnOrOff(event.checked);
    }

    onVolumeChange(event: MatSliderDragEvent) {
        if (!event) return;
        const newValue = event.value;
        this.audioListManager.triggerNewVolume(newValue);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}
