import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import WaveSurfer from 'wavesurfer.js';
import { WavesurferManagerService } from './wavesurfer-manager.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-waveform',
    templateUrl: './waveform.component.html',
    styleUrls: ['./waveform.component.scss'],
})
export class WaveformComponent implements OnInit, OnDestroy {
    @ViewChild('waveform', { static: true }) waveformRef!: ElementRef;
    @Input({ alias: 'audioUrl', required: true }) audioUrl: string;
    @Input({ alias: 'height' }) height: number = 100;
    @Input({ alias: 'uniqueId' }) uniqueId: string = '';

    onDestroy$ = new Subject<void>();
    constructor(private wavesurfManager: WavesurferManagerService) {}
    ngOnInit(): void {
        this.initializeWavesurfer();
        this.wavesurfManager.triggerPlayPauseFromOutside$.pipe(takeUntil(this.onDestroy$)).subscribe((payload) => {
            if (!payload) return;
            if (payload.uniqueId !== this.uniqueId) return;

            this.togglePlay(payload.playOrPause);
        });
    }

    waveSurfer!: WaveSurfer;

    initializeWavesurfer(): void {
        this.waveSurfer = WaveSurfer.create({
            container: this.waveformRef.nativeElement,
            waveColor: '#9a70e5',
            progressColor: 'black',
            height: this.height,
            barGap: 1,
            barHeight: 0.6,
            barRadius: 2,
            barWidth: 1,
        });
        this.waveSurfer.load(this.audioUrl);
        this.waveSurfer.on('seeking', () => {
            this.wavesurfManager.triggerPlayFromWaves(this.uniqueId);
        });
    }

    togglePlay(playOrPause?: boolean): void {
        if (typeof playOrPause !== 'boolean') {
            this.waveSurfer.playPause();
            return;
        }

        if (playOrPause === this.waveSurfer.isPlaying()) {
            return;
        }

        if (playOrPause) {
            this.waveSurfer.play();
            return;
        }
        this.waveSurfer.pause();
    }

    ngOnDestroy(): void {
        if (this.waveSurfer) {
            this.waveSurfer.destroy();
        }
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
