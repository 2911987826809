import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IUserPaymentStatus, PaymentUtilsService } from '../../payment/payment-utils.service';
import { ProfileService } from '../../show/profile.service';
import { User } from '../../../models/user';
interface IPaymentTextConfig {
    matIcon: 'diamond_icon' | 'bolt';
    text: string;
}
@Injectable({
    providedIn: 'root',
})
export class PaymentStateManagerService {
    private _paymentStatus$ = new BehaviorSubject<IUserPaymentStatus>({
        needToPayNow: false,
        plan: null,
        needToPay: false,
        abilityToClosePaywall: true,
        paywallTimeout: 4000,
    });
    public paymentStatus$ = this._paymentStatus$.asObservable();

    private _paymentShortText$ = new BehaviorSubject<IPaymentTextConfig>(null);
    public paymentShortText$ = this._paymentShortText$.asObservable();

    private _paymentLongText$ = new BehaviorSubject<IPaymentTextConfig>(null);
    public paymentLongText$ = this._paymentLongText$.asObservable();
    constructor(
        private paymentUtilsService: PaymentUtilsService,
        private profileService: ProfileService
    ) {
        this.profileService.user$.subscribe((user) => {
            this.setUserNeedsToPayAsync(user);
        });
    }

    async setUserNeedsToPayAsync(user: User) {
        if (!user) {
            return;
        }
        const doesUserNeedsToPay = await this.checkIfUserNeedsToPayAsync(user);
        this._paymentStatus$.next(doesUserNeedsToPay);
        if (!doesUserNeedsToPay) return;
        const { needToPay, needToPayNow, plan } = doesUserNeedsToPay;
        if (!needToPay) {
            this._paymentShortText$.next(null);
            this._paymentLongText$.next(null);
            return;
        }

        if (plan === 'trial') {
            this._paymentShortText$.next({ text: 'Upgrade Now', matIcon: 'diamond_icon' });
            this._paymentLongText$.next(null);
            return;
        }

        if (plan === 'immediate') {
            if (needToPayNow) {
                this._paymentShortText$.next({ text: 'Upgrade Now', matIcon: 'diamond_icon' });
                this._paymentLongText$.next({
                    text: 'You already used the your free trial video, but getting more is easy ⚡',
                    matIcon: 'diamond_icon',
                });
                return;
            }
            this._paymentShortText$.next({ text: 'Enjoy 1 Free Video!', matIcon: 'diamond_icon' });
            this._paymentLongText$.next({
                text: 'You are using the trial version. Your first video is on us 🎁',
                matIcon: 'diamond_icon',
            });
            return;
        }
    }

    async checkIfUserNeedsToPayAsync(user: User) {
        try {
            const doesUserNeedsToPay = await this.paymentUtilsService.checkIfUserNeedsToPayAsync(user);
            return doesUserNeedsToPay;
        } catch (error) {
            return null;
        }
    }

    getNeedToPayValue() {
        return this._paymentStatus$.value;
    }
}
