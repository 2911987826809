import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WavesurferManagerService {
    private _triggerPlayFromWavesSubject = new Subject<string>();

    public triggerPlayFromWaves$ = this._triggerPlayFromWavesSubject.asObservable();

    private _triggerPlayFromOutsideSubject = new Subject<{ uniqueId: string; playOrPause: boolean }>();
    public triggerPlayPauseFromOutside$ = this._triggerPlayFromOutsideSubject.asObservable();
    constructor() {}

    triggerPlayFromWaves(uniqueId: string) {
        this._triggerPlayFromWavesSubject.next(uniqueId);
    }

    triggerPlayFromOutside(uniqueId: string) {
        this._triggerPlayFromOutsideSubject.next({ uniqueId: uniqueId, playOrPause: true });
    }

    triggerPauseFromOutside(uniqueId: string) {
        this._triggerPlayFromOutsideSubject.next({ uniqueId: uniqueId, playOrPause: false });
    }
}
