import { Injectable, Input } from '@angular/core';
import { IAudioConfig, IAudioListComponentSettings } from './audio-list.component';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class AudioListManagerService {
    private _alwaysShowAllSubject = new BehaviorSubject<boolean>(false);
    alwaysShowAll$ = this._alwaysShowAllSubject.asObservable();

    private _isLoadingDataSubject = new BehaviorSubject<boolean>(false);
    isLoadingData$ = this._isLoadingDataSubject.asObservable();

    private _audioOptionsSubject = new BehaviorSubject<IAudioConfig[]>(null);
    audioOptions$ = this._audioOptionsSubject.asObservable();

    private _selectedAudioSubject = new BehaviorSubject<IAudioConfig>(null);
    selectedAudio$ = this._selectedAudioSubject.asObservable();

    private currentSelectedAudioId: string;

    private _triggerNewAudioIdSubject = new Subject<string>();
    triggerNewSelectedAudioId$ = this._triggerNewAudioIdSubject.asObservable();

    private _triggerOnOrOffSubject = new Subject<boolean>();
    triggerOnOrOff$ = this._triggerOnOrOffSubject.asObservable();

    private _triggerNewVolumeSubject = new Subject<number>();
    triggerNewVolume$ = this._triggerNewVolumeSubject.asObservable();

    private _audioListComponentSettings = new BehaviorSubject<IAudioListComponentSettings>(null);
    audioListComponentSettings$ = this._audioListComponentSettings.asObservable();

    private _startedPlayingSampleSubject = new Subject<void>();
    public startedPlayingSample$ = this._startedPlayingSampleSubject.asObservable();

    private _stopPlayingSampleSubject = new Subject<void>();
    public stopPlayingSample$ = this._stopPlayingSampleSubject.asObservable();
    constructor() {}

    setAudioOptions(audioOptions: IAudioConfig[]) {
        if (audioOptions?.length > 0) {
            this.markSelectedAudioOption(audioOptions);
        }
        this._audioOptionsSubject.next(audioOptions);
    }
    setComponentSettings(componentConfigs: IAudioListComponentSettings) {
        this._audioListComponentSettings.next(componentConfigs);
    }

    triggerNewSelectForAudioId(audioId: string) {
        if (!audioId) {
            return;
        }

        this._triggerNewAudioIdSubject.next(audioId);
    }

    triggerOnOrOff(isOn: boolean) {
        this._triggerOnOrOffSubject.next(isOn);
    }

    triggerNewVolume(volume: number) {
        this._triggerNewVolumeSubject.next(volume);
    }

    setCurrentSelectedAudio(audioId: string) {
        const audioOptions = this._audioOptionsSubject.value;
        const selectedAudio = audioOptions?.find((audio) => audio.id === audioId);
        this.currentSelectedAudioId = audioId;
        this.setSelectedAudio(selectedAudio);
    }

    setLoadingForAudio(audioId: string, isLoading: boolean) {
        const audioOptions = this._audioOptionsSubject.value;
        if (!audioOptions) return;
        const selectedAudio = audioOptions.find((audio) => audio.id === audioId);
        if (!selectedAudio) return;
        selectedAudio.isLoading = isLoading;
        if (isLoading) {
            this.isLoadingData = true;
            return;
        }
        let isLoadingData = false;
        for (const audioOption of audioOptions) {
            if (audioOption.isLoading) {
                isLoadingData = true;
                break;
            }
        }
        this.isLoadingData = isLoadingData;
    }

    triggerStartPlayingSample() {
        this._startedPlayingSampleSubject.next();
    }

    triggerStopPlayingSample() {
        this._stopPlayingSampleSubject.next();
    }

    resetConfigs() {
        this._audioOptionsSubject.next(null);
        this._selectedAudioSubject.next(null);
        this._audioListComponentSettings.next(null);
        this._alwaysShowAllSubject.next(false);
    }
    set alwaysShowAll(toEnable: boolean) {
        this._alwaysShowAllSubject.next(toEnable);
    }
    private set isLoadingData(isLoadingData: boolean) {
        this._isLoadingDataSubject.next(isLoadingData);
    }

    private setSelectedAudio(selectedVoice: IAudioConfig) {
        this._selectedAudioSubject.next(selectedVoice);
        this.markSelectedAudioOption(this._audioOptionsSubject.value);
    }

    private markSelectedAudioOption(audioOptions: IAudioConfig[]) {
        if (!audioOptions || audioOptions.length === 0) {
            return;
        }
        for (const audioOption of audioOptions) {
            audioOption.isSelected = audioOption.id === this.currentSelectedAudioId;
        }
    }
}
