import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AudioListManagerService } from '../audio-list-manager.service';
import { ConfigurationService } from '../../../../../../services/configuration.service';
import { WavesurferManagerService } from '../../../../../minor/waveform/wavesurfer-manager.service';
import { IAudioConfig, IAudioListComponentSettings } from '../audio-list.component';

@Component({
    selector: 'app-audio-list-item',
    templateUrl: './audio-list-item.component.html',
    styleUrl: './audio-list-item.component.scss',
})
export class AudioListItemComponent {
    @Input() audioConfig: IAudioConfig;
    @Input() basePath: string;
    @Input() disabled: boolean = false;
    @Output() togglePlay = new EventEmitter<string>();
    @Input() currentPlayingId: string;
    @Output() chooseVoice = new EventEmitter<IAudioConfig>();
    @Output() askToChange = new EventEmitter();
    @Input() selectMode = true;

    onTogglePlay() {
        this.togglePlay.emit(this.audioConfig.id);
    }

    onChooseAudio() {
        this.chooseVoice.emit(this.audioConfig);
    }

    onAskToChange() {
        this.askToChange.emit();
    }
}
