import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../configuration.service';
import {
    IFeedbackConfigs,
    IOnBoardingConfig,
    IStorytellingTechniqueConfig,
    IToneOfVoiceConfig,
    IVideoTagsConfig,
} from '../../../models/defines';
import { IFontsConfigs } from '../../../models/configs/fonts.model';
import { IBGMusic } from '../../../models/configs/background-music.model';
import { IAiVoiceConfigs } from '../../../models/ai-voice.model';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationAuthApiService {
    path = '/auth/config';

    constructor(
        private http: HttpClient,
        private config: ConfigurationService
    ) {}

    getOnboardingConfig() {
        return this.http.get<IOnBoardingConfig>(`${this.config.apiIP + this.path}/onboarding`).toPromise();
    }

    getVideoTagsConfig() {
        return this.http.get<IVideoTagsConfig>(`${this.config.apiIP + this.path}/video_tags`).toPromise();
    }

    getTonesConfig() {
        return this.http.get<IToneOfVoiceConfig>(`${this.config.apiIP + this.path}/tone_of_voice`).toPromise();
    }

    getStorytellingTeqniquesConfig() {
        return this.http
            .get<IStorytellingTechniqueConfig>(`${this.config.apiIP + this.path}/storytelling_techniques`)
            .toPromise();
    }

    getFeedbackConfig() {
        return this.http.get<IFeedbackConfigs>(`${this.config.apiIP + this.path}/feedback_forms`).toPromise();
    }

    getFonts$() {
        return this.http.get<IFontsConfigs>(`${this.config.apiIP + this.path}/fonts`);
    }

    getBGMusic$() {
        return this.http.get<IBGMusic[]>(`${this.config.apiIP + this.path}/music-library`);
    }
    getAiVoicesOptionsConfig() {
        return this.http.get<IAiVoiceConfigs>(`${this.config.apiIP + this.path}/ai_voice_options`);
    }
}
